import "./App.css";
import logo from "./images/logo.png";
import daily from "./images/daily.jpg";
import gift from "./images/gift.jpg";
import event from "./images/event.jpg";
import { Navbar, Container, Nav, Col, Row } from "react-bootstrap";
import { useEffect, useRef } from "react";
import { Routes, Route, Link } from "react-router-dom";
import Notice from "./components/Notice.jsx";
import Daily from "./components/Daily.jsx";
import Gift from "./components/Gift.jsx";
import Event from "./components/Event.jsx";

const Map = () => {
  const mapElement = useRef(null);

  useEffect(() => {
    const { naver } = window;
    if (!mapElement.current || !naver) return;

    const location = new naver.maps.LatLng(37.5272628, 127.030865);
    const mapOptions = {
      center: location,
      zoom: 17,
      zoomControl: true,
      zoomControlOptions: {
        position: naver.maps.Position.TOP_RIGHT,
      },
    };
    const map = new naver.maps.Map(mapElement.current, mapOptions);
    new naver.maps.Marker({
      position: location,
      map,
    });
  }, []);
  return <div ref={mapElement} style={{ minHeight: "300px" }} />;
};

const Footer = () => {
  return (
    <div style={{ marginTop: 10 }}>
      <p>
        주소: 서울특별시 강남구 압구정로34길 11 압구정스퀘어 112호 | 전화: 02-518-7245 | 팩스: 02-518-7246
      </p>
      <p>Copyright © 2023 대치떡방 All rights reserved.</p>
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <Navbar className="nav">
        <Container>
          <Navbar.Brand href="/" className="nav-home">
            <img src={logo} alt="logo" style={{ height: 70, width: 70 }} />
            압구정 대치떡방
          </Navbar.Brand>
          <Nav className="nav-list">
            <Nav.Link href="notice">공지사항</Nav.Link>
            <Nav.Link href="daily">일반떡</Nav.Link>
            <Nav.Link href="gift">선물세트</Nav.Link>
            <Nav.Link href="event">명절/잔치/행사</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div className="main-image" />
              <Container>
                <Row>
                  <Col md className="nav-image">
                    <Link to="/daily">
                      <img src={daily} alt="daily" width="80%" />
                    </Link>
                    <p />
                    <h4>일반떡</h4>
                    <p>다양한 떡을 식사 또는 간식으로 즐겨보세요.</p>
                  </Col>
                  <Col md className="nav-image">
                    <Link to="/gift">
                      <img src={gift} alt="gift" width="80%" />
                    </Link>
                    <p />
                    <h4>선물세트</h4>
                    <p>축하나 감사를 전하고 싶을 때 선물하세요.</p>
                  </Col>
                  <Col md className="nav-image">
                    <Link to="/event">
                      <img src={event} alt="event" width="80%" />
                    </Link>
                    <p />
                    <h4>특별한 날</h4>
                    <p>명절, 잔치, 행사와 같은 특별한 날을 위해 준비하세요.</p>
                  </Col>
                </Row>
              </Container>
              <Map />
            </>
          }
        ></Route>
        <Route path="/notice" element={<Notice />}></Route>
        <Route path="/daily" element={<Daily />}></Route>
        <Route path="/gift" element={<Gift />}></Route>
        <Route path="/event" element={<Event />}></Route>
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
