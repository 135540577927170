import "./Notice.css";

const Notice = () => {
  return (
    <>
      <div className="notice-image" />
      <h1>공지사항</h1>
      <div className="order">
        <h3>주문/ 배송 안내</h3>
        <p className="notice-contents">
          1. 중요한 날 2~3일 전 미리 연락주세요. 📞 02-518-7245
          <br />
          2. 제품명, 수량, 배송지 주소 및 받으시는 분 연락처 등 정보를
          알려주세요.
          <br />
          3. 국민은행 350-21-0018-131 (예금주: 우종규)로 송금해 주세요.
          <br />
          4. 대치떡방에 들러 주문한 떡을 찾아가 주세요.
          <br />
        </p>
        <p className="notice-contents">
          🏍️ <strong>당일 배송</strong>을 원칙으로 하고 있어, 퀵 서비스로 배송이
          됩니다.
          <br />
          거리에 따라 배송비가 달라집니다. 전화 주문 시 배송지를 말씀해주세요.
          <br />
          (경기도 수도권 지역까지 가능합니다)
          <br />
          기타 지방 배송은 퀵 서비스와 고속버스를 활용합니다. 사전에
          말씀해주세요.
        </p>
      </div>
      <div className="howto">
        <h3>떡 보관 요령</h3>
        <p className="notice-contents">
          당일 못드실 경우 냉동보관 해주시기 바랍니다.
          <br />
          영하 18℃ 환경에서 떡이 겹치지 않게 하여 냉동해주세요. 
          <br />
          (겹쳐서 보관될 경우 겹친 부분이 얼지 않고 굳어버린답니다)
          <br />
          약 1~2시간 정도 자연해동 해주시거나, 전자레인지에 약 30초 정도 해동해주세요.
          <br />
          전기밥솥에 보온상태로 약 20분 정도 넣어두시거나, 
          <br/>찜기에 약 15분 정도 쪄주시는 것도 좋습니다.
          <br />
        </p>
      </div>
    </>
  );
};

export default Notice;
