let dailyData = [
  {
    id: 0,
    title: "꿀떡",
  },
  {
    id: 1,
    title: "절편",
  },
  {
    id: 2,
    title: "바람떡",
  },
  {
    id: 3,
    title: "가래떡",
  },
  {
    id: 4,
    title: "떡볶이떡",
  },
  {
    id: 5,
    title: "시루떡",
  },
  {
    id: 6,
    title: "쑥개떡",
  },
  {
    id: 7,
    title: "미니설기",
  },
  {
    id: 8,
    title: "인절미",
  },
  {
    id: 9,
    title: "콩밤떡",
  },
  {
    id: 10,
    title: "완두찰떡",
  },
  {
    id: 11,
    title: "호박찰떡",
  },
  {
    id: 12,
    title: "모듬찰떡",
  },
  {
    id: 13,
    title: "흑미찰떡",
  },
  {
    id: 14,
    title: "콩깨찰떡",
  },
  {
    id: 15,
    title: "콩찰떡",
  },
  {
    id: 16,
    title: "호박설기",
  },
  {
    id: 17,
    title: "약식",
  },
];

let eventData = [
  {
    id: 18,
    title: "술떡",
  },
  {
    id: 19,
    title: "수수팥떡",
  },
  {
    id: 20,
    title: "경단",
  },
  {
    id: 21,
    title: "목판세트 (쑥굴레 + 두텁떡)",
  },
  {
    id: 22,
    title: "이바지 선물용",
  },
  {
    id: 23,
    title: "떡국떡",
  },
  {
    id: 24,
    title: "송편",
  },
  {
    id: 25,
    title: "백일설기",
  },
  {
    id: 26,
    title: "봉채떡 (함떡)",
  },
  {
    id: 27,
    title: "호박설기 케이크",
  },
  {
    id: 28,
    title: "콩+호박설기 케이크",
  },
];

let giftData = [
  {
    id: 29,
  },
  {
    id: 30,
  },
  {
    id: 31,
  },
  {
    id: 32,
  },
  {
    id: 33,
  },
  {
    id: 34,
  },
];

export { dailyData, eventData, giftData };
