import { dailyData } from "../data.js";
import { useState } from "react";

const Daily = () => {
  let [name] = useState(dailyData);

  return (
    <>
      <h1>식사/ 간식용 떡</h1>
      <div className="container">
        <div className="row">
          {name.map((item, i) => {
            return <Card name={name[i]} i={i + 1} />;
          })}
        </div>
      </div>
    </>
  );
};

const Card = (props) => {
  return (
    <>
      <div className="col-md-4">
        <img src={"/images/" + props.i + ".jpg"} alt={props.i} width="100%" />
        <p />
        <h4>{props.name.title}</h4>
      </div>
    </>
  );
};

export default Daily;
