import { giftData } from "../data.js";
import { useState } from "react";

const Gift = () => {
  let [name] = useState(giftData);
  return (
    <>
      <h1>선물세트</h1>
      <div className="giftset">
        <p
          style={{
            padding: "1%",
            backgroundColor: "rgba(255, 228, 196, 0.5)",
            marginBottom: "2%",
            fontSize: "150%",
          }}
        >
          📋<br/>
          찰떡 개수에 따라 가격이 달라집니다.
          <br />
          원하시는 만큼 조절 가능하니 따로 문의주세요.
        </p>
      </div>
      <div className="container">
        <div className="row">
          {name.map((item, i) => {
            return <Card name={name[i]} i={i + 30} />;
          })}
        </div>
      </div>
      <div className="giftset">
        <h3>포장</h3>
        <p
          style={{
            padding: "1%",
            backgroundColor: "rgba(255, 228, 196, 0.199)",
            marginBottom: "2%",
            fontSize: "150%",
          }}
        >
          🎀 자체 스티커 있으시면 변경 가능합니다.
          <br />
          필요하신 상황에 따라 맞춤 주문 가능하니, 문의주세요.
        </p>
      </div>
      <div className="container">
        <div className="row">
          {name.map((item, i) => {
            return <Card2 i={i + 36} />;
          })}
        </div>
      </div>
    </>
  );
};

const Card = (props) => {
  return (
    <>
      <div className="col-md-4">
        <img
          style={{ height: "30vh" }}
          src={"/images/" + props.i + ".jpg"}
          alt={props.i}
          width="100%"
        />
        <p />
        <h4>{props.name.title}</h4>
      </div>
    </>
  );
};

const Card2 = (props) => {
  return (
    <>
      <div className="col-md-4">
        <img
          style={{ height: "30vh" }}
          src={"/images/" + props.i + ".jpg"}
          alt={props.i}
          width="100%"
        />
        <p />
      </div>
    </>
  );
};

export default Gift;
